import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Signup from "app/pages/Auth/Signup/Signup.jsx";
import {
	sendTagOnSponsorshipSubscribeFailed,
	sendTagOnSponsorshipSubscribeSuccess,
} from "app/utils/analytics";
import { brandPropTypes, partnerShape } from "app/utils/propTypes";
import {
	logout,
	registerPromise,
	signupPromise,
	sponsorshipSubscribe,
} from "app/pages/Auth/AuthActions";
import { useIntl } from "react-intl";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import RegisterMessage from "app/pages/Auth/Signup/RegisterMessage";
import { getParentPath } from "app/utils/routerUtils";
import { BRANDS } from "app/constants";
import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { resetSearchMyBooking } from "app/pages/Account/SearchMyBooking/searchMyBookingActionCreators";
import { getInscriptionCreditCampaign } from "app/pages/Account/MyCoupons/couponSelector";
import { IconCredit } from "app/pages/.shared/static/icons";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { FormattedMessage } from "react-intl";
import { fetchCreditCampaign } from "app/pages/Account/MyCoupons/couponActionCreators";

const SignupContainer = ({
	cnilDocumentName,
	confidentialiteDocumentName,
	headline,
	footer,
	showTopFacebook = true,
	onAuthSuccess,
	onToogleSigninForm,
	signupButtonLabel,
	source,
	partner,
	parentSponsorId,
	email,
	helpContactUrl,
	shop,
	brand,
	logout,
	dispatch,
	resetSearchMyBooking = () => {},
	inscriptionCreditCampaign,
	fetchCreditCampaign = () => {},
	creditCampaign = [],
}) => {
	const intl = useIntl();
	const location = useLocation();
	const navigate = useNavigate();
	const onToogleSignin =
		onToogleSigninForm && typeof onToogleSigninForm === "function"
			? onToogleSigninForm
			: () => {
					const parentPath = getParentPath(location.pathname);
					navigate({ pathname: `${parentPath}/login` });
			  };

	const enableFacebookLogin = brand !== BRANDS.AX;
	const handleFacebookOnSuccess = useCallback((success, dispatch) => {
		if (parentSponsorId && partner.enableSponsorship) {
			sponsorshipSubscribe(parentSponsorId, success.data.email, success.data.token)
				.then(() => {
					sendTagOnSponsorshipSubscribeSuccess({
						sponsored: success.data.email,
						parentSponsorId: parentSponsorId,
					});
				})
				.catch(err => {
					sendTagOnSponsorshipSubscribeFailed({
						sponsored: success.data.email,
						status: err.response.data.status || err.response.status,
					});
				});
		}

		if (onAuthSuccess && typeof onAuthSuccess === "function") {
			onAuthSuccess(success, dispatch);
		} else {
			navigate("/listing", { replace: true });
		}
	}, []);

	const handleSubmit = useCallback(
		(values, { setErrors, setSubmitting }) => {
			logout();
			resetSearchMyBooking();
			return partner?.isDoubleOptin
				? registerPromise({
						data: values,
						partner,
						navigate,
						dispatch,
						shop,
						onAuthSuccess,
						formHandle: { setErrors, setSubmitting },
				  })
				: signupPromise({
						data: values,
						navigate,
						dispatch,
						partner,
						source,
						parentSponsorId,
						onAuthSuccess,
						formHandle: { setErrors, setSubmitting },
				  });
		},
		[source, parentSponsorId, partner, navigate, shop]
	);

	let headlineNode = headline;
	if (inscriptionCreditCampaign && inscriptionCreditCampaign?.amount) {
		headlineNode = (
			<div className="auth__credit-banner" data-testid="auth-credit-banner">
				<IconCredit className="auth__credit-icon" />
				<FormattedMessage
					id="auth.credit.campaign.signup.label"
					tagName="span"
					values={{
						amount: <AmountContainer amount={inscriptionCreditCampaign.amount} />,
					}}
				/>
			</div>
		);
	}

	useEffect(() => {
		if (brand && shop && creditCampaign?.length === 0) {
			fetchCreditCampaign({ shop, brandCode: brand });
		}
	}, [brand, shop]);

	return (
		<Routes>
			<Route
				path="/*"
				element={
					<RegisterMessage
						email={email}
						partner={partner}
						helpContactUrl={helpContactUrl}
						intl={intl}
						shop={shop}
					/>
				}
			/>

			<Route
				path="/"
				element={
					<Signup
						cnilDocumentName={cnilDocumentName}
						confidentialiteDocumentName={confidentialiteDocumentName}
						headline={headlineNode}
						footer={footer}
						showTopFacebook={enableFacebookLogin && showTopFacebook}
						signupButtonLabel={signupButtonLabel}
						handleSubmit={handleSubmit}
						facebookOnSuccess={handleFacebookOnSuccess}
						onToogleSigninForm={onToogleSignin}
					/>
				}
			/>
		</Routes>
	);
};

SignupContainer.propTypes = {
	parentSponsorId: PropTypes.string,
	source: PropTypes.string,
	helpContactUrl: PropTypes.string,
	shop: PropTypes.string,
	email: PropTypes.string,
	partner: partnerShape,
	onAuthSuccess: PropTypes.func,
	logout: PropTypes.func,
	onToogleSigninForm: PropTypes.func,
	headline: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	showTopFacebook: PropTypes.bool,
	confidentialiteDocumentName: PropTypes.string,
	cnilDocumentName: PropTypes.string,
	signupButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	brand: brandPropTypes,
	dispatch: PropTypes.func,
	resetSearchMyBooking: PropTypes.func,
	inscriptionCreditCampaign: PropTypes.object,
	fetchCreditCampaign: PropTypes.func,
	creditCampaign: PropTypes.array,
};

const mapDispatchToProps = dispatch => ({
	...bindActionCreators(
		{
			logout,
			resetSearchMyBooking,
			fetchCreditCampaign,
		},
		dispatch
	),
	dispatch,
});

const mapStateToProps = state => {
	const contextCallCenter = getContextCallCenter({})(state);
	return {
		confidentialiteDocumentName: state.documents.confidentialite,
		cnilDocumentName: state.documents.cnil,
		source: state.marketing.source,
		partner: state.partner,
		parentSponsorId: state.sponsorship.parentSponsorId,
		helpContactUrl: contextCallCenter.helpContactUrl,
		email: state.email,
		shop: state.shop,
		brand: state.brand.code,
		inscriptionCreditCampaign: getInscriptionCreditCampaign(state),
		creditCampaign: state.coupons.creditCampaign,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SignupContainer);

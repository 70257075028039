import { getStore } from "app/configureStore";
import env from "app/utils/env";
import axios from "axios";
import { FETCH_LANDING_PAGES_AUTH } from "app/pages/LandingPage/Auth/LandingAuthActionTypes";

export const getLandingPagesAuth = () => {
	const state = getStore().getState();
	const shop = state.shop;
	const endpoint = env("CONTENT_API_URL") || env("BASE_URL");

	return {
		type: FETCH_LANDING_PAGES_AUTH,
		promise: axios.get(
			`${endpoint}/${state.productsVisibility}/resources/${shop}/landingPage.json`
		),
	};
};

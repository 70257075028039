import { memo, useCallback } from "react";
import PropTypes from "prop-types";
import "./MarketingBanner.scss";
import { useLocation, useParams } from "react-router-dom";
import Paragraphs from "app/pages/.shared/Paragraphs";
import { isServerSide, isUserMatchWithType } from "app/utils/utils";
import { useFlagship, useFsFlag } from "@flagship.io/react-sdk";

const MarketingBanner = ({ banners = [], userIsConnected }) => {
	const fsShowMarketingBanner = useFsFlag("marketing_banner_show", true);
	const { status: fsStatus } = useFlagship();

	let { productUri } = useParams();
	const isFicheProduitPage = Boolean(productUri);
	const location = useLocation();

	const shouldShowBanner = useCallback(
		banner => {
			const { message, pages = [], usersFilter = [] } = banner;
			const checkUserType = isUserMatchWithType(usersFilter, userIsConnected);
			const isValidPage = pages.find(page => {
				return (
					location.pathname === page ||
					(page === "/:productUri" && isFicheProduitPage) ||
					(page === "/sdp/listing" && location.pathname.includes(page))
				);
			});
			return (
				checkUserType &&
				isValidPage &&
				(fsStatus.isSdkReady || (!isServerSide && window.Cypress)) &&
				fsShowMarketingBanner.getValue() &&
				message
			);
		},
		[location, userIsConnected, fsStatus.isSdkReady]
	);

	if (banners.length === 0) {
		return null;
	}

	return banners
		.filter(shouldShowBanner)
		.map(({ message, backgroundColor, textColor, promoCode }, index) => {
			return (
				<div
					className="marketing-banner"
					data-testid="marketing-banner"
					style={{ background: backgroundColor?.hex, color: textColor?.hex }}
					key={index}
				>
					{promoCode && (
						<span
							className="marketing-banner__promocode"
							data-testid="marketing-banner-promocode"
						>
							{promoCode}
						</span>
					)}
					<Paragraphs paragraphs={message} enableHTMLFormatting />
				</div>
			);
		});
};

MarketingBanner.propTypes = {
	banners: PropTypes.array,
	userIsConnected: PropTypes.bool,
};

export default memo(MarketingBanner);

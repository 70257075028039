// import React from "react";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import promiseMiddleware from "./utils/promiseMiddleware";
// import performanceReduxMiddleware from "./utils/performanceReduxMiddleware";
import reducers from "./reducers";
import { autoRehydrate } from "redux-persist";

import { REHYDRATE } from "redux-persist/constants";
import createActionBuffer from "redux-action-buffer";

import { sentryMiddleware } from "app/utils/sentry";
import { isServerSide } from "app/utils/utils";

const middlewares = [thunk, promiseMiddleware, sentryMiddleware];

let store;

export function configureStore(initialState) {
	if (!isServerSide) {
		middlewares.push(createActionBuffer(REHYDRATE));

		// if (process.env.NODE_ENV !== "production") {
		// 	middlewares.push(performanceReduxMiddleware);
		// }
	}

	let middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer];

	if (typeof window !== "undefined") {
		enhancers.push(autoRehydrate());
	}

	// if (process.env.NODE_ENV !== "production") {
	// @see https://github.com/garbles/why-did-you-update/issues/45
	// let createClass = React.createClass; // eslint-disable-line no-unused-vars
	// Object.defineProperty(React, 'createClass', {
	// 	set: (nextCreateClass) => {
	// 		createClass = nextCreateClass;
	// 	}
	// });
	// const {whyDidYouUpdate} = require('why-did-you-update');
	// whyDidYouUpdate(React, { include: /./ });
	// }

	const composedEnhancers = composeWithDevTools({ trace: process.env.NODE_ENV !== "production" })(
		...enhancers
	);

	store = createStore(reducers, initialState, composedEnhancers);

	if (!isServerSide && window.Cypress) {
		window.store = store;
	}

	if (process.env.NODE_ENV !== "production" && module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept("./reducers", () => {
			const nextRootReducer = require("./reducers");
			store.replaceReducer(nextRootReducer.default);
		});
	}

	return store;
}

export function getStore() {
	return store;
}

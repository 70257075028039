import { createSelector } from "reselect";
import { OFFER_TYPES, PAYMENT_METHODS, PAYMENT_OPTIONS } from "app/constants";
import {
	getBookingOfferType,
	getFlight,
	getHasSpecialOffer,
	getQuoteTotalWithDelta,
	isBookingEligibleForDeposit,
} from "app/pages/Booking/bookingSelectors";
import { calculateEcheancier } from "app/utils/echeancierUtils";
import uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import values from "lodash/values";
import { getSpecialOffer } from "app/pages/FicheProduit/ficheProduitSelector";
import { hasQuoteSpecialOffer } from "app/pages/Booking/Quotation/quotationSelectors";

const getPaymentTypes = state => get(state, "payment.paymentTypes");
export const getBookingPaymentType = state => get(state, "booking.paymentType");
export const getBookingPaymentMode = state => get(state, "booking.paymentMode");
const getnformationStandardFormHiddenOnHotelOnly = state =>
	get(state, "documents.isInformationStandardFormHiddenOnHotelOnly");
const getInformationStandardForm = state => get(state, "documents.informationStandardForm");
const getPartner = state => state.partner;

export const checkIfPaymentCasino4xDisponible = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		const paymentType4Fois = paymentTypes.find(paymentType => {
			return (
				paymentType.paymentType === PAYMENT_METHODS.CASINO &&
				paymentType.terms === PAYMENT_OPTIONS["4x"]
			);
		});

		return paymentType4Fois ? true : false;
	}
);

export const checkIfPaymentAlmaAvailable = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		return paymentTypes.some(paymentType => paymentType.paymentType === PAYMENT_METHODS.ALMA);
	}
);

export const checkIfPaymentPledg4xDisponible = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		return paymentTypes.some(
			paymentType =>
				paymentType.paymentType === PAYMENT_METHODS.PLEDG &&
				paymentType.terms === PAYMENT_OPTIONS["4x"]
		);
	}
);

export const calculatePartialPaymentAmount = createSelector(
	[getQuoteTotalWithDelta, getPaymentTypes, isBookingEligibleForDeposit],
	(total = 0, paymentTypes = [], isBookingEligibleForDeposit = false) => {
		const partialPayment = paymentTypes.find(paymentType => {
			return paymentType.terms === PAYMENT_OPTIONS["2x"];
		});

		if (partialPayment) {
			const payNowAmount = isBookingEligibleForDeposit
				? Math.ceil((total * partialPayment.percentagePayNow) / 100)
				: (total * partialPayment.percentagePayNow) / 100;

			const payLaterAmount = total - payNowAmount;

			return {
				dueDate: partialPayment.dueDate,
				percentagePayNow: partialPayment.percentagePayNow,
				payLaterAmount,
				payNowAmount,
			};
		}
		return {};
	}
);

/**
 * retourne les infos de frais 4x (pledg ou casino)
 */
export const getFees4X = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		return paymentTypes.find(e => e.terms === PAYMENT_OPTIONS["4x"])?.fees;
	}
);

/**
 * Voici la règle de calcul concernant l’échéancier :
 * Taux appliqués
 * De 60              à 1000.00            : 2.39%
 * De 1001              à 1500.00            : 2.19%
 * De 1500.01         à 2000.00            : 1.97%
 * De 2000.01         à 2500.00            : 1.70%
 * De 2500            à 10000.00           : 1.48%
 * La règle est la suivante : les échéances 2 à 4 doivent être égales.
 * La première échéance permet de récupérer les centimes restants.
 *
 * Exemple :
 *
 * Panier à 130.47 <sch:TotalAmount>13047</sch:TotalAmount> en entrée du Score
 *
 * Taux appliqué 2.35%
 *
 * Panier avec frais 133.536045 <TotalAmount>13354</TotalAmount> en sortie du Score
 *
 * Echéancier 4X : 133.536045/4 = 33.38401125
 *
 * Echéance 2 : 33.38
 * Echéance 3 : 33.38
 * Echéance 4 : 33.38
 * Echéance 1 : 133.536045 – (3x33.38) = 33.396045 => 33.40
 */
export const getEcheancierCasino = createSelector(
	[getQuoteTotalWithDelta, getFees4X],
	(total = 0, fees4x) => {
		return calculateEcheancier({ total, fees: fees4x, terms: 4 });
	}
);

export const getSupportedPaymentTypes = createSelector(
	[getPaymentTypes],
	(paymentTypes = []) => {
		return paymentTypes.filter(paymentType => {
			return values(PAYMENT_METHODS).includes(paymentType.paymentType);
		});
	}
);

/**
 * retourne la liste de modes de paiements (1, 2, 4) triés par ordre croissant.
 * Un mode de paiement est le nombre possibles de mensualités
 */
export const getPaymentModes = createSelector(
	[getSupportedPaymentTypes, getQuoteTotalWithDelta],
	(paymentTypes = [], total = 0) => {
		const paymentModes = paymentTypes.map(paymentType => {
			const instalments = calculateEcheancier({
				total,
				fees: paymentType.fees,
				terms: paymentType.terms,
			});
			return { type: paymentType.paymentType, terms: paymentType.terms, instalments };
		});

		const uniquPaymentMode = uniqBy(paymentModes, mode => mode.terms);

		const sortedUniqPaymentModes = sortBy(uniquPaymentMode, mode => mode.terms);

		return sortedUniqPaymentModes;
	}
);

/**
 * retourne le mode de payment actuel qui peut être :
 * - soit celui que l'utilisateur a sélectionné
 * - soit le plus petit mode de paiement disponible
 * - soit 1 sit la totalité
 */
export const getCurrentPaymentMode = createSelector(
	[getBookingPaymentMode, getPaymentModes],
	(selectedPaymentMode, paymentModes = []) => {
		if (selectedPaymentMode) {
			return selectedPaymentMode;
		} else if (paymentModes[0]) {
			return paymentModes[0].terms;
		}
		return PAYMENT_OPTIONS["1x"];
	}
);

export const getAllowedPaymentTypes = createSelector(
	[getCurrentPaymentMode, getSupportedPaymentTypes],
	(selectedPaymentMode, paymentTypes = []) => {
		if (!selectedPaymentMode) {
			return [];
		}
		return paymentTypes.filter(paymentType => paymentType.terms === selectedPaymentMode);
	}
);

export const getAvailablePaymentMethods = createSelector(
	[getPartner],
	partner => {
		const paymentMethods = partner?.availablePaymentMethods;

		if (!paymentMethods) {
			return [];
		}
		return paymentMethods.reduce((accumulator, payment) => {
			const newPayments = payment.termsChoices.map(term => {
				return { terms: term, type: payment.code };
			});
			return accumulator.concat(newPayments);
		}, []);
	}
);

/**
 * Par défaut, on choisit ce que l'utilisateur a selectionné en tant que type de paiement.
 * Si l'utilisateur n'a pas sélectionné de moyen de paiement, alors on prend la valeur du premier type de paiement disponible
 **/
export const getCurrentPaymentType = createSelector(
	[getBookingPaymentType, getAllowedPaymentTypes],
	(selectedPaymentType, allowedPaymentTypes = []) => {
		if (
			selectedPaymentType &&
			values(
				allowedPaymentTypes.map(allowedPaymentType => allowedPaymentType.paymentType)
			).includes(selectedPaymentType)
		) {
			return selectedPaymentType;
		}

		return get(allowedPaymentTypes, "[0].paymentType");
	}
);

const getInsurancesItems = state => state.payment.insurances;
const getPassengersNumber = state =>
	state.booking.adults + state.booking.children + state.booking.infants;
const getTripDuration = state => state.booking.duration.value;

export const checkSpecialOfferForSelectedDepartureDate = createSelector(
	[getSpecialOffer, getHasSpecialOffer],
	(specialOffer, hasSpecialOffer) => {
		return specialOffer && !isEmpty(specialOffer) && hasSpecialOffer;
	}
);

export const checkSpecialOfferOnQuote = createSelector(
	[checkSpecialOfferForSelectedDepartureDate, hasQuoteSpecialOffer],
	(hasSpecialOffer, hasSpecialOfferOnQuote) => {
		return hasSpecialOffer && hasSpecialOfferOnQuote;
	}
);

export const checkForSpecialOffer = createSelector(
	[checkSpecialOfferOnQuote, getFlight],
	(hasSpecialOfferOnQuote, selectedFlight = {}) => {
		return hasSpecialOfferOnQuote && (isEmpty(selectedFlight) || selectedFlight.specialOffer);
	}
);

export const getInsurancesSplittedPrice = createSelector(
	[getInsurancesItems, getPassengersNumber, getTripDuration],
	(insurances = [], passengerNumber = 1, tripDuration = 1) => {
		return {
			...insurances,
			insurancesItem:
				(insurances.insurancesItem &&
					insurances.insurancesItem.map(insurance => ({
						...insurance,
						splittedPrice: insurance.price / (passengerNumber * tripDuration),
					}))) ||
				[],
		};
	}
);

export const shouldShowInformationStandardForm = createSelector(
	[getnformationStandardFormHiddenOnHotelOnly, getInformationStandardForm, getBookingOfferType],
	(isInformationStandardFormHiddenOnHotelOnly = false, informationStandardForm, offerType) => {
		if (!informationStandardForm) {
			return false;
		}

		return !(
			isInformationStandardFormHiddenOnHotelOnly &&
			offerType === OFFER_TYPES.ACCOMODATION_ONLY
		);
	}
);

export const getEcheancier = createSelector(
	[getQuoteTotalWithDelta, getCurrentPaymentMode, getPaymentTypes],
	(total = 0, selectedPaymentMode, paymentTypes) => {
		const fees = paymentTypes.find(e => e.terms === selectedPaymentMode)?.fees;
		return calculateEcheancier({ total, fees, terms: selectedPaymentMode });
	}
);

export const getPaymentDepositPercentage = createSelector(
	[getPaymentTypes, getBookingPaymentType, getBookingPaymentMode],
	(paymentTypes = [], bookingPaymentType, bookingPaymentMode) => {
		const paymentByCard = paymentTypes.find(
			methode =>
				methode.paymentType === bookingPaymentType && methode.terms === bookingPaymentMode
		);
		return paymentByCard?.percentagePayNow;
	}
);

import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { calculateAgeFromBirthday } from "app/utils/utils";
import { isAfter } from "date-fns";
import { getFieldErrorNames } from "app/pages/.shared/form/formikUtils";

import { MAX_PASSENGERS_ALLOWED } from "app/constants";

export const defaultValues = {
	departureCity: "",
	destinationResort: "",
	travelDates: {
		departureDate: "",
		endDate: "",
	},
	duration: {
		code: "",
	},
	occupancies: [{ adults: 2, children: 0, childrenBirthdates: [] }],
	cabin: "",
};

export const validateDepartureCity = values => {
	let error;

	if (!values) {
		error = {
			id: "sdp.search.departure.city.input.error",
		};
	}
	return error;
};

export const validateDestination = values => {
	let error;

	if (!values) {
		error = {
			id: "sdp.search.destination.input.error",
		};
	}

	return error;
};

export const validateDepartureDate = values => {
	let error;

	if (isEmpty(values)) {
		error = {
			id: "sdp.search.departure.date.input.error",
		};
	}

	return error;
};

export const validateDuration = values => {
	let error;

	if (!values) {
		error = {
			id: "sdp.search.duration.input.error",
		};
	}

	return error;
};

export const validateSearch = (values = {}) => {
	let errors = {};

	errors = validateTravellersRoomFormSearch(values);

	// on force la re validation des champs departureCity et destinationResort
	// quand le user clique sur rechercher
	const departureCityError = validateDepartureCity(values.departureCity);
	if (departureCityError) {
		errors.departureCity = departureCityError;
	}

	const destinationResortError = validateDestination(values.destinationResort);
	if (destinationResortError) {
		errors.destinationResort = destinationResortError;
	}

	// duration is falsy when the selected travel dates range from emirates website is too large
	const durationError = validateDuration(values?.duration?.code);

	if (durationError) {
		errors.travelDates = durationError;
	}

	return errors;
};

export const validateTravellersRoomFormSearch = (values = {}) => {
	const errors = {};
	const today = new Date();
	const occupancies = values.occupancies || [];
	let totalPersonsCount = 0;
	occupancies.forEach((occupancy, index) => {
		const childrenBirthdates = occupancy.childrenBirthdates || [];
		const adults = occupancy.adults;
		const children = occupancy.children;
		totalPersonsCount += adults + children;

		childrenBirthdates.forEach((childrenBirthdate, birdthdateIndex) => {
			if (
				!childrenBirthdate ||
				isUndefined(childrenBirthdate.day) ||
				isUndefined(childrenBirthdate.month) ||
				isUndefined(childrenBirthdate.year)
			) {
				if (!errors.occupancies) {
					errors.occupancies = [];
				}

				if (!errors.occupancies[index]) {
					errors.occupancies[index] = {};
				}

				if (!errors.occupancies[index].childrenBirthdates) {
					errors.occupancies[index] = { childrenBirthdates: [] };
				}

				errors.occupancies[index].childrenBirthdates[birdthdateIndex] = {
					id: "error.form.generic",
				};
			}
			if (
				childrenBirthdate &&
				!isEmpty(childrenBirthdate) &&
				childrenBirthdate.day >= 0 &&
				childrenBirthdate.month >= 0 &&
				childrenBirthdate.year >= 0 &&
				isAfter(
					new Date(
						childrenBirthdate.year,
						childrenBirthdate.month,
						childrenBirthdate.day,
						0,
						0,
						0
					),
					new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
				)
			) {
				if (!errors.occupancies) {
					errors.occupancies = [];
				}

				if (!errors.occupancies[index]) {
					errors.occupancies[index] = {};
				}

				if (!errors.occupancies[index].childrenBirthdates) {
					errors.occupancies[index] = { childrenBirthdates: [] };
				}
				errors.occupancies[index].childrenBirthdates[birdthdateIndex] = {
					id: "sdp.search.children.ages.past.today.error.label",
				};
			}
		});

		const babies = childrenBirthdates.filter(childrenBirthdate => {
			let result = false;

			if (
				childrenBirthdate &&
				!isEmpty(childrenBirthdate) &&
				childrenBirthdate.day >= 0 &&
				childrenBirthdate.month >= 0 &&
				childrenBirthdate.year >= 0
			) {
				const birdthdate = new Date(
					childrenBirthdate.year,
					childrenBirthdate.month,
					childrenBirthdate.day,
					0,
					0,
					0
				);

				const age = calculateAgeFromBirthday(birdthdate);

				result = age <= 2;
			}

			return result;
		});

		if (babies.length > adults) {
			if (!errors.occupancies) {
				errors.occupancies = [];
			}
			errors.occupancies[index] = {};
			errors.occupancies[index] = { childrenBirthdates: [] };
			errors.occupancies[index].childrenBirthdates = childrenBirthdates.map(() => {
				return {
					id: "sdp.search.children.ages.baby.more.adult.error.label",
				};
			});
		}
	});

	if (totalPersonsCount > MAX_PASSENGERS_ALLOWED) {
		errors.formGlobalErrors = [{ id: "error.search.engine.max.passengers" }];
	}

	const fieldErrorNames = getFieldErrorNames(errors);
	const element = document.querySelector(`input[name='${fieldErrorNames[0]}']`);
	if (element) {
		element.scrollIntoView({ behavior: "smooth", block: "center" });
	}
	return errors;
};

import { memo, useCallback, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import OccupanciesDisplayLabel from "app/pages/.shared/OccupanciesDisplayLabel/OccupanciesDisplayLabel";
import IconHorizontalePlane from "app/pages/.shared/IconHorizontalePlane";
import "./SmartDPSearchSummary.scss";
import { useNavigate } from "react-router-dom";
import SidePanel from "app/pages/.shared/SidePanel/SidePanel";
import { sendTagOnClickFilterMobile } from "app/utils/analytics";
import loadable from "@loadable/component";
import { buildSDPSearchPayload } from "app/utils/smartDPUtils";
import TravelDatesContainer from "app/pages/.shared/TravelDates/TravelDatesContainer";
import SvgIconMagnifyingGlassBold from "app/pages/.shared/IconMagnifyingGlassBold";
import { FormattedMessage } from "react-intl";
import { validateTravellersRoomFormSearch } from "app/pages/SmartDP/Search/SDPSearchForm/smartDPSearchFormSchema";
import { isEmpty } from "lodash";

export const AsyncSmartDPSearchFormContainer = loadable(() =>
	import(/* webpackChunkName: "smartdp-search" */ "app/pages/SmartDP/Search/SDPSearchForm/SmartDPSearchFormContainer")
);

const SmartDPSearchSummary = ({
	departureCityLabel,
	departureResortLabel,
	occupancies,
	resetAllSDPProductsFilter,
}) => {
	const navigate = useNavigate();
	const [showSearch, toggleSearch] = useState(false);

	const handleShowFilters = useCallback(() => {
		toggleSearch(true);
		sendTagOnClickFilterMobile();
	}, []);

	const handleHideSearch = useCallback(() => {
		toggleSearch(false);
	}, []);

	const handleFormSubmission = useCallback(data => {
		const queryParams = buildSDPSearchPayload(data);

		resetAllSDPProductsFilter();

		handleHideSearch();

		navigate({ pathname: "/sdp/listing", search: queryParams }, { replace: true });
	}, []);

	const travellersErrors = validateTravellersRoomFormSearch({ occupancies });
	const travellerClassName = classNames("sdp-search-summary__occupancies", {
		"sdp-search-summary__occupancies--error": !isEmpty(travellersErrors),
	});

	return (
		<div className="sdp-search-summary">
			<div className="sdp-search-summary__details" onClick={handleShowFilters}>
				<div
					className="sdp-search-summary__itinerary"
					data-testid="sdp-search-summary-itinerary"
				>
					{departureCityLabel ? (
						departureCityLabel
					) : (
						<span className="sdp-search-summary__itinerary--error">
							<FormattedMessage id="sdp.search.departure.city.input.label" />
						</span>
					)}{" "}
					<IconHorizontalePlane />
					{departureResortLabel ? (
						departureResortLabel
					) : (
						<span className="sdp-search-summary__itinerary--error">
							<FormattedMessage id="sdp.search.destination.input.label" />
						</span>
					)}
				</div>
				<div
					className="sdp-search-summary__dates-durations"
					data-testid="sdp-search-summary-dates"
				>
					<TravelDatesContainer />
				</div>
				<div className={travellerClassName} data-testid="sdp-search-summary-occupancies">
					<OccupanciesDisplayLabel occupancies={occupancies} />
				</div>
			</div>
			<div className="sdp-search-summary__search">
				<div className="sdp-search-summary__magnifying-glass" onClick={handleShowFilters}>
					<SvgIconMagnifyingGlassBold />
				</div>
				<SidePanel
					isOpen={showSearch}
					direction={"rtl"}
					onClose={handleHideSearch}
					className="sdp-search-summary__side-panel"
				>
					<AsyncSmartDPSearchFormContainer onSuccess={handleFormSubmission} />
				</SidePanel>
			</div>
		</div>
	);
};

SmartDPSearchSummary.propTypes = {
	departureCityLabel: PropTypes.string,
	departureResortLabel: PropTypes.string,
	occupancies: PropTypes.array,
	resetAllSDPProductsFilter: PropTypes.func,
};

SmartDPSearchSummary.defaultProps = {
	occupancies: [],
	resetAllSDPProductsFilter: () => {},
};

export default memo(SmartDPSearchSummary);

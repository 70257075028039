import React, { useState, useEffect } from "react";

const RumErrorBoundary = ({ fallback, scope = "error-boundary", children }) => {
	const [hasError, setHasError] = useState(false);
	const [error, setError] = useState(null);
	const [prevScope, setPrevScope] = useState(scope);

	useEffect(() => {
		const RumGlobal = window?.DD_RUM;

		if (hasError && error && RumGlobal) {
			RumGlobal.addError(
				error,
				{
					scope: scope,
				},
				"source"
			);
		}
	}, [hasError, error, scope]);

	useEffect(() => {
		if (prevScope !== scope) {
			setHasError(false);
			setError(null);
			setPrevScope(scope);
		}
	}, [prevScope, scope]);

	if (!hasError || !error) {
		return children;
	}

	if (React.isValidElement(fallback) || typeof fallback === "string") {
		return fallback;
	} else if (typeof fallback === "function") {
		return fallback(error);
	}

	return null;
};

export default RumErrorBoundary;

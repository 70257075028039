import { memo } from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import { brandPropTypes } from "app/utils/propTypes";
import RelativeLink from "app/pages/.shared/RelativeLink";
import { useLocation } from "react-router-dom";
import Paragraphs from "app/pages/.shared/Paragraphs";
import IconPictoChevron from "app/pages/.shared/IconPictoChevron";
import IconAwesomeLock from "app/pages/.shared/IconAwesomeLock";
import { BRANDS } from "app/constants";
import { sendTagOnMenuGoToAuth } from "app/utils/analytics";
import AccountMenuFooterContainer from "app/pages/Header/AccountMenu/AccountMenuFooter/AccountMenuFooterContainer";

import "./AccountMenuUnauthenticated.scss";

const AccountMenuUnauthenticated = ({
	brand,
	onItemClick = () => {},
	menuConnectMessages = [],
}) => {
	const { pathname, search } = useLocation();

	const onauthsuccessUrl = encodeURIComponent(`${pathname}${search}`);

	const marketingMessageNode =
		menuConnectMessages?.length > 0 ? (
			<div className="signup-menu-account__dynamic-message">
				<Paragraphs paragraphs={menuConnectMessages} enableHTMLFormatting />
				<RelativeLink
					to={{
						pathname: "/booking-auth",
						search: `?onauthsuccess=${onauthsuccessUrl}`,
					}}
					data-testid="go-to-booking-auth-link"
					onClick={() => {
						sendTagOnMenuGoToAuth();
						onItemClick();
					}}
				>
					<div className="signup-menu-account__signup-login-button">
						<FormattedMessage id="menu.mobile.register.login" />
						<IconPictoChevron />
					</div>
				</RelativeLink>
			</div>
		) : (
			<div className="signup-menu-account__signup-intro">
				<IconAwesomeLock height={20} width={20} />
				<FormattedMessage id="menu.mobile.signup.to.flashsales.intro" tagName="h4" />
			</div>
		);

	const isBrandPS = brand === BRANDS.PS;

	return (
		<div
			className="account-menu__modal account-menu--unauthenticated"
			data-testid="account-menu-unauthenticated"
		>
			{!isBrandPS && marketingMessageNode}
			<nav
				className={classNames({
					"account-menu__body": true,
					"signup-menu-account__body--without-marketing-msg": isBrandPS,
				})}
			>
				<ul className="signup-menu-account__nav">
					{!menuConnectMessages && !isBrandPS && (
						<li className="signup-menu-account__nav-item">
							<RelativeLink
								to={{
									pathname: "/booking-auth",
									search: `?onauthsuccess=${onauthsuccessUrl}`,
								}}
								data-testid="go-to-booking-auth-link"
								onClick={() => {
									sendTagOnMenuGoToAuth();
									onItemClick();
								}}
							>
								<FormattedMessage id="menu.mobile.login.signup" tagName="h4" />
							</RelativeLink>
						</li>
					)}
					<AccountMenuFooterContainer onItemClick={onItemClick} isAuthenticated={false} />
				</ul>
			</nav>
		</div>
	);
};

AccountMenuUnauthenticated.propTypes = {
	brand: brandPropTypes,
	onItemClick: PropTypes.func,
	menuConnectMessages: PropTypes.array,
};

export default memo(AccountMenuUnauthenticated);

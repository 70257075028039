import {
	FETCH_SPONSORSHIP_SUCCESS,
	GET_SPONSOR_ID_SUCCESS,
	LOGOUT,
	SET_SPONSOR_ID,
	RESET_PARENT_SPONSOR_ID,
} from "app/actionTypes";

const defaultState = {
	sponsorId: undefined,
	parentSponsorId: undefined,
	rejectedEmails: {},
	currentFriends: [],
	historyFriends: [],
	sponsorshipCredit: {},
	totalCredits: undefined,
	usableCredits: undefined,
	creditIds: [],
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case GET_SPONSOR_ID_SUCCESS:
			return Object.assign({}, state, {
				sponsorId: action.res.data.sponsorId,
			});
		case SET_SPONSOR_ID:
			return Object.assign({}, state, {
				parentSponsorId: action.sponsorId,
			});
		case FETCH_SPONSORSHIP_SUCCESS: {
			const current = action.res.data.current || {};
			const history = action.res.data.history || {};

			const currentFriends = Object.keys(current).map(email => {
				return {
					email: email,
					friendSubscribed: current[email].friendSubscribed,
					friendBooked: current[email].friendBooked,
				};
			});

			const historyFriends = Object.keys(history).map(email => {
				return {
					email: email,
					friendSubscribed: history[email].friendSubscribed,
					friendBooked: history[email].friendBooked,
				};
			});

			return Object.assign({}, state, {
				currentFriends,
				historyFriends,
				sponsorshipCredit: action.res.data.sponsorshipCredit,
			});
		}
		case RESET_PARENT_SPONSOR_ID:
			return {
				...state,
				parentSponsorId: undefined,
			};
		case LOGOUT:
			return {
				...defaultState,
				// we call logout before signup (line 83 in SignupContainer.jsx), we need hydrate parentSponsorId in case signup from email sponsorship
				parentSponsorId: state.parentSponsorId,
			};
		default:
			return state;
	}
};

import { createSelector } from "reselect";
import { BRANDS, STRATEGY } from "app/constants";
import { getCurrentShop } from "app/reducers/shopSelector";
import { getShopData } from "app/utils/utils";

const getStrategy = state => state.partner.strategy;

export const getBrand = state => state.brand?.code;
export const getShopsList = state => state.brand?.shops;

export const isWhiteLabel = createSelector(
	[getStrategy, getBrand],
	(strategy, brand) => {
		// brands pour lesquelles on veut passer en transactionFirst mais que tous les accès à mon compte sont possibles.
		// TODO En dur dans le code, le temps de tester si ca convertit bien.
		// Si oui, il faut trouver une solution plus pérenne.
		const temporaryElligibleBrands = brand === BRANDS.TO || brand === BRANDS.EK;

		return (
			strategy === STRATEGY.SIGNUP_FIRST ||
			strategy === STRATEGY.NAVIGATION_FIRST ||
			strategy === STRATEGY.PRODUCT_FIRST ||
			temporaryElligibleBrands
		);
	}
);

export const hasCustomDynamicHeader = createSelector(
	[getBrand],
	brand => {
		return brand === BRANDS.CD || brand === BRANDS.SP || brand === BRANDS.HP;
	}
);

export const getDefaultPartnerCodeForShop = createSelector(
	[getShopsList, getCurrentShop],
	(shops = [], shop) => {
		const shopData = getShopData(shops, shop);
		const [lang] = shop.split("-");
		const defaultMarketingCodeData = shopData?.marketingCodes.find(marketingCode => {
			return marketingCode.isDefaultMarketingCodeForShop && marketingCode.locale === lang;
		});
		return defaultMarketingCodeData?.code;
	}
);

export const FETCH_SDP_FILTERS = "FETCH_SDP_FILTERS";
export const FETCH_SDP_FILTERS_SUCCESS = "FETCH_SDP_FILTERS_SUCCESS";
export const FETCH_SDP_AVAILABLE_DEPARTURE_DATE = "FETCH_SDP_AVAILABLE_DEPARTURE_DATE";
export const FETCH_SDP_QUOTE = "FETCH_SDP_QUOTE";
export const FETCH_SDP_QUOTE_FAILURE = "FETCH_SDP_QUOTE_FAILURE";
export const FETCH_SDP_QUOTE_REQUEST = "FETCH_SDP_QUOTE_REQUEST";
export const FETCH_SDP_QUOTE_SUCCESS = "FETCH_SDP_QUOTE_SUCCESS";
export const SAVE_SELECTED_SDP_PRODUCT = "SAVE_SELECTED_SDP_PRODUCT";
export const SHOW_MORE_SDP_PRODUCTS = "SHOW_MORE_SDP_PRODUCTS";
export const SAVE_SDP_SEARCH_QUERY_STRING = "SAVE_SDP_SEARCH_QUERY_STRING";
export const FETCH_SDP_ACCOMMODATION_DESCRIPTION = "FETCH_SDP_ACCOMMODATION_DESCRIPTION";
export const FETCH_SDP_ACCOMMODATION_DESCRIPTION_REQUEST =
	"FETCH_SDP_ACCOMMODATION_DESCRIPTION_REQUEST";
export const FETCH_SDP_ACCOMMODATION_DESCRIPTION_SUCCESS =
	"FETCH_SDP_ACCOMMODATION_DESCRIPTION_SUCCESS";
export const CHECK_SDP_AVAILABILITIES = "CHECK_SDP_AVAILABILITIES";
export const CHECK_SDP_AVAILABILITIES_REQUEST = "CHECK_SDP_AVAILABILITIES_REQUEST";
export const CHECK_SDP_AVAILABILITIES_SUCCESS = "CHECK_SDP_AVAILABILITIES_SUCCESS";
export const UPDATE_ACTIVE_SORT = "UPDATE_ACTIVE_SORT";
export const CLEAR_SMARTDP = "CLEAR_SMARTDP";

import { useContext } from "react";
import AnalyticsContext from "app/utils/analytics/AnalyticsContext";
import {
	checkIfSDPPages,
	mapFicheProduitStoreToTag,
	mapPageStoreToTag,
	mapPrePackageStoreToTag,
	mapQuoteStoreToTag,
	mapUserStoreToTag,
} from "app/utils/analytics-store-mapper";
import { useLocation } from "react-router-dom";
import { sendDataToAnalyticsEndPoint } from "app/utils/analytics";
import { datadogRum } from "@datadog/browser-rum";

const dataLayer = typeof window !== "undefined" ? window.dataLayer : [];

export const useAnalytics = () => {
	const { store = {} } = useContext(AnalyticsContext);
	const { pathname, search } = useLocation();

	return {
		track: (eventName, payload = {}) => {
			if (dataLayer) {
				const state = store.getState() || {};

				// We can have product, prePackage and quote data here even if it is not relevant depending on the pages
				// To simplify, we can always put thoses data in every event. Data team will pick up the right data depending on the event name
				const commonData = {
					merchCode: state.merchandising?.current?.code,
					brand: state.brand.code,
					isSdp: checkIfSDPPages(state, pathname),
					shop: state.shop,
					page: mapPageStoreToTag({ pathname, search, state }),
					partnerCode: state.partner?.code,
					hotjarSessionId: window._hjSettings?.hjid, // id de session de recording hotjar
					user: mapUserStoreToTag(state),
					product: mapFicheProduitStoreToTag(state.ficheProduit),
					prePackage: mapPrePackageStoreToTag(state),
					quote: mapQuoteStoreToTag(state),
				};

				const tagData = {
					event: eventName,
					...commonData,
					...payload,
				};

				dataLayer.push(tagData);

				if (process.env.NODE_ENV === "production") {
					sendDataToAnalyticsEndPoint(tagData);

					const { event, ...restTagData } = tagData;

					datadogRum.addAction(event, {
						...restTagData,
					});
				}
			}
		},
	};
};

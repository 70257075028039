export const UPDATE_THEME = "UPDATE_THEME";
export const ACCEPT_COOKIES_POLICY = "ACCEPT_COOKIES_POLICY";
export const ACCEPT_MAP_POLICY = "ACCEPT_MAP_POLICY";
export const PAY_DUE_BOOKING = "PAY_DUE_BOOKING";
export const PAY_DUE_BOOKING_REQUEST = "PAY_DUE_BOOKING_REQUEST";
export const PAY_DUE_BOOKING_SUCCESS = "PAY_DUE_BOOKING_SUCCESS";
export const PAY_DUE_BOOKING_FAILURE = "PAY_DUE_BOOKING_FAILURE";
export const SAVE_NEWSLETTER_PREFERENCES = "SAVE_NEWSLETTER_PREFERENCES";
export const SAVE_NEWSLETTER_PREFERENCES_SUCCESS = "SAVE_NEWSLETTER_PREFERENCES_SUCCESS";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const UPDATE_SDP_FORM_DESTINATION = "UPDATE_SDP_FORM_DESTINATION";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const UPDATE_BOOKING_PASSENGER = "UPDATE_BOOKING_PASSENGER";
export const UPDATE_BOOKING_ACTIVITY = "UPDATE_BOOKING_ACTIVITY";
export const UPDATE_BOOKING_ACTIVITY_DATE = "UPDATE_BOOKING_ACTIVITY_DATE";
export const UPDATE_BOOKING_CHILDREN_BIRTHDATES = "UPDATE_BOOKING_CHILDREN_BIRTHDATES";
export const UPDATE_BOOKING_INFANTS_BIRTHDATES = "UPDATE_BOOKING_INFANTS_BIRTHDATES";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const FETCH_RESA_TRIP_DETAIL = "FETCH_RESA_TRIP_DETAIL";
export const FETCH_RESA_TRIP_DETAIL_SUCCESS = "FETCH_RESA_TRIP_DETAIL_SUCCESS";
export const CLEAR_PRODUCT_PAGE = "CLEAR_PRODUCT_PAGE";
export const FETCH_FIRST_PRODUCTS = "FETCH_FIRST_PRODUCTS";
export const FETCH_FIRST_PRODUCTS_SUCCESS = "FETCH_FIRST_PRODUCTS_SUCCESS";
export const FETCH_FIRST_PRODUCTS_FAILURE = "FETCH_FIRST_PRODUCTS_FAILURE";
export const FETCH_FICHE_PRODUIT = "FETCH_FICHE_PRODUIT";
export const FETCH_FICHE_PRODUIT_URI = "FETCH_FICHE_PRODUIT_URI";
export const FETCH_FICHE_PRODUIT_REQUEST = "FETCH_FICHE_PRODUIT_REQUEST";

export const FETCH_FICHE_PRODUIT_SUCCESS = "FETCH_FICHE_PRODUIT_SUCCESS";
export const FETCH_FICHE_PRODUIT_FAILURE = "FETCH_FICHE_PRODUIT_FAILURE";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE";
export const SET_PRODUCTS_FILTER_BY = "SET_PRODUCTS_FILTER_BY";
export const UNSET_PRODUCTS_FILTER_BY = "UNSET_PRODUCTS_FILTER_BY";
export const RESET_PRODUCTS_FILTER = "RESET_PRODUCTS_FILTER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const FACEBOOK_LOGIN_SUCCESS = "FACEBOOK_LOGIN_SUCCESS";
export const COGNITO_ID_RETRIEVED = "COGNITO_ID_RETRIEVED";
export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";
export const SAVE_PROFILE = "SAVE_PROFILE";
export const SAVE_PROFILE_REQUEST = "SAVE_PROFILE_REQUEST";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_FAILURE = "SAVE_PROFILE_FAILURE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_UPDATE_TOKEN = "AUTH_UPDATE_TOKEN";
export const TOGGLE_ASIDE_MENU = "TOGGLE_ASIDE_MENU";
export const HIDE_ASIDE_MENU = "HIDE_ASIDE_MENU";
export const OPEN_SLIDESHOW = "OPEN_SLIDESHOW";
export const CLOSE_SLIDESHOW = "CLOSE_SLIDESHOW";
export const FETCH_PRE_PACKAGE_PRICES = "FETCH_PRE_PACKAGE_PRICES";
export const FETCH_PRE_PACKAGE_PRICES_SUCCESS = "FETCH_PRE_PACKAGE_PRICES_SUCCESS";
export const FETCH_PRE_PACKAGE_PRICES_FAILURE = "FETCH_PRE_PACKAGE_PRICES_FAILURE";
export const HIDE_STICKY_PRICE = "HIDE_STICKY_PRICE";
export const SHOW_STICKY_PRICE = "SHOW_STICKY_PRICE";
export const LOGOUT = "LOGOUT";
export const CLEAR_QUOTATION = "CLEAR_QUOTATION";
export const CHECK_AVAILABILITIES = "CHECK_AVAILABILITIES";
export const CHECK_AVAILABILITIES_REQUEST = "CHECK_AVAILABILITIES_REQUEST";
export const CHECK_AVAILABILITIES_SUCCESS = "CHECK_AVAILABILITIES_SUCCESS";
export const CHECK_AVAILABILITIES_FAILURE = "CHECK_AVAILABILITIES_FAILURE";
export const BOOK = "BOOK";
export const BOOK_REQUEST = "BOOK_REQUEST";
export const BOOK_SUCCESS = "BOOK_SUCCESS";
export const BOOK_FAILURE = "BOOK_FAILURE";
export const RESTORE_BOOKING_SESSION = "RESTORE_BOOKING_SESSION";
export const RESTORE_BOOKING_SESSION_REQUEST = "RESTORE_BOOKING_SESSION_REQUEST";
export const RESTORE_BOOKING_SESSION_FAILURE = "RESTORE_BOOKING_SESSION_FAILURE";
export const RESTORE_BOOKING_SESSION_SUCCESS = "RESTORE_BOOKING_SESSION_SUCCESS";

export const PRE_BOOK = "PRE_BOOK";
export const PRE_BOOK_REQUEST = "PRE_BOOK_REQUEST";
export const PRE_BOOK_FAILURE = "PRE_BOOK_FAILURE";
export const PRE_BOOK_SUCCESS = "PRE_BOOK_SUCCESS";
export const SET_EMAIL = "SET_EMAIL";
export const SET_STRATEGY = "SET_STRATEGY";
export const SET_PARTNER = "SET_PARTNER";
export const SET_FLIGHTS_SORT_BY = "SET_FLIGHTS_SORT_BY";
export const SET_FLIGHTS_FILTER_BY = "SET_FLIGHTS_FILTER_BY";
export const SHOW_MORE_FLIGHTS = "SHOW_MORE_FLIGHTS";
export const RESET_FLIGHTS_FILTER = "RESET_FLIGHTS_FILTER";
export const RESET_FLIGHTS_PAGE_SIZE = "RESET_FLIGHTS_PAGE_SIZE";
export const UPDATE_RESPONSIVE_STATE = "UPDATE_RESPONSIVE_STATE";
export const SEND_PROMOTION_CODE = "SEND_PROMOTION_CODE";
export const SEND_PROMOTION_CODE_REQUEST = "SEND_PROMOTION_CODE_REQUEST";
export const SEND_PROMOTION_CODE_SUCCESS = "SEND_PROMOTION_CODE_SUCCESS";
export const SEND_PROMOTION_CODE_FAILURE = "SEND_PROMOTION_CODE_FAILURE";
export const CLEAR_PROMOTION_VALUE = "CLEAR_PROMOTION_VALUE";
export const SET_ID = "SET_ID";
export const SET_TOKEN_FROM_PARTNER = "SET_TOKEN_FROM_PARTNER";
export const FETCH_MY_BOOKING = "FETCH_MY_BOOKING";
export const FETCH_SEARCH_MY_BOOKING = "FETCH_SEARCH_MY_BOOKING";
export const FETCH_SEARCH_MY_BOOKING_SUCCESS = "FETCH_SEARCH_MY_BOOKING_SUCCESS";
export const FETCH_SEARCH_MY_BOOKING_FAILURE = "FETCH_SEARCH_MY_BOOKING_FAILURE";
export const FETCH_SEARCH_MY_BOOKING_NOTIFICATION = "FETCH_SEARCH_MY_BOOKING_NOTIFICATION";
export const FETCH_SEARCH_MY_BOOKING_NOTIFICATION_SUCCESS =
	"FETCH_SEARCH_MY_BOOKING_NOTIFICATION_SUCCESS";
export const FETCH_SEARCH_MY_BOOKING_NOTIFICATION_FAILURE =
	"FETCH_SEARCH_MY_BOOKING_NOTIFICATION_FAILURE";
export const RESET_SEARCH_MY_BOOKING = "RESET_SEARCH_MY_BOOKING";
export const FETCH_MY_BOOKING_REQUEST = "FETCH_MY_BOOKING_REQUEST";
export const FETCH_MY_BOOKING_SUCCESS = "FETCH_MY_BOOKING_SUCCESS";
export const FETCH_MY_BOOKING_FAILURE = "FETCH_MY_BOOKING_FAILURE";
export const FETCH_MY_BOOKINGS = "FETCH_MY_BOOKINGS";
export const FETCH_MY_BOOKINGS_REQUEST = "FETCH_MY_BOOKINGS_REQUEST";
export const FETCH_MY_BOOKINGS_SUCCESS = "FETCH_MY_BOOKINGS_SUCCESS";
export const FETCH_MY_BOOKINGS_NOTIFICATION = "FETCH_MY_BOOKINGS_NOTIFICATION";
export const FETCH_MY_BOOKINGS_NOTIFICATION_SUCCESS = "FETCH_MY_BOOKINGS_NOTIFICATION_SUCCESS";
export const FETCH_MY_BOOKINGS_NOTIFICATION_FAILURE = "FETCH_MY_BOOKINGS_NOTIFICATION_FAILURE";
export const FETCH_FLIGHT_OPTIONS = "FETCH_FLIGHT_OPTIONS";
export const FETCH_FLIGHT_OPTIONS_REQUEST = "FETCH_FLIGHT_OPTIONS_REQUEST";
export const FETCH_FLIGHT_OPTIONS_SUCCESS = "FETCH_FLIGHT_OPTIONS_SUCCESS";
export const FETCH_FLIGHT_OPTIONS_FAILURE = "FETCH_FLIGHT_OPTIONS_FAILURE";
export const UPDATE_EXTERNAL_BOOK_STATUS = "UPDATE_EXTERNAL_BOOK_STATUS";
export const BOOK_EXTERNAL_PAYMENT = "BOOK_EXTERNAL_PAYMENT";
export const BOOK_EXTERNAL_PAYMENT_SUCCESS = "BOOK_EXTERNAL_PAYMENT_SUCCESS";
export const BOOK_EXTERNAL_PAYMENT_FAILURE = "BOOK_EXTERNAL_PAYMENT_FAILURE";
export const BOOK_EXTERNAL_PAYMENT_REQUEST = "BOOK_EXTERNAL_PAYMENT_REQUEST";
export const BOOK_EXTERNAL_HTML_REQUEST = "BOOK_EXTERNAL_HTML_REQUEST";
export const FETCH_TOKEN_INFO_SUCCESS = "FETCH_TOKEN_INFO_SUCCESS";
export const FETCH_TOKEN_INFO_FAILURE = "FETCH_TOKEN_INFO_FAILURE";
export const LOGOUT_FROM_ALL_DEVICES_REQUEST = "LOGOUT_FROM_ALL_DEVICES_REQUEST";
export const LOGOUT_FROM_ALL_DEVICES_SUCCESS = "LOGOUT_FROM_ALL_DEVICES_SUCCESS";
export const LOGOUT_FROM_ALL_DEVICES_FAILURE = "LOGOUT_FROM_ALL_DEVICES_FAILURE";
export const LOGOUT_FROM_ONE_DEVICES_SUCCESS = "LOGOUT_FROM_ONE_DEVICES_SUCCESS";
export const LOGOUT_FROM_ONE_DEVICES_FAILURE = "LOGOUT_FROM_ONE_DEVICES_FAILURE";
export const UPDATE_MARKETING_DATA = "UPDATE_MARKETING_DATA";
export const SET_PARTNER_DATA = "SET_PARTNER_DATA";
export const START_PRINT = "START_PRINT";
export const RESET_PRINT = "RESET_PRINT";
export const SEARCH_BOOKING = "SEARCH_BOOKING";
export const SEARCH_BOOKING_SUCCESS = "SEARCH_BOOKING_SUCCESS";
export const SEARCH_BOOKING_FAILURE = "SEARCH_BOOKING_FAILURE";
export const FETCH_SPONSORSHIP = "FETCH_SPONSORSHIP";
export const FETCH_SPONSORSHIP_REQUEST = "FETCH_SPONSORSHIP_REQUEST";
export const FETCH_SPONSORSHIP_SUCCESS = "FETCH_SPONSORSHIP_SUCCESS";
export const FETCH_SPONSORSHIP_FAILURE = "FETCH_SPONSORSHIP_FAILURE";
export const SPONSOR_FRIENDS = "SPONSOR_FRIENDS";
export const SPONSOR_FRIENDS_REQUEST = "SPONSOR_FRIENDS_REQUEST";
export const SPONSOR_FRIENDS_SUCCESS = "SPONSOR_FRIENDS_SUCCESS";
export const SPONSOR_FRIENDS_FAILURE = "SPONSOR_FRIENDS_FAILURE";
export const SET_SPONSOR_ID = "SET_SPONSOR_ID";
export const GET_SPONSOR_ID = "GET_SPONSOR_ID";
export const GET_SPONSOR_ID_REQUEST = "GET_SPONSOR_ID_REQUEST";
export const GET_SPONSOR_ID_SUCCESS = "GET_SPONSOR_ID_SUCCESS";
export const GET_SPONSOR_ID_FAILURE = "GET_SPONSOR_ID_FAILURE";
export const HIDE_ASIDE_PRODUCT_FILTERS = "HIDE_ASIDE_PRODUCT_FILTERS";
export const TOGGLE_ASIDE_PRODUCT_FILTERS = "TOGGLE_ASIDE_PRODUCT_FILTERS";
export const FETCH_LANDING_PAGES = "FETCH_LANDING_PAGES";
export const FETCH_LANDING_PAGES_REQUEST = "FETCH_LANDING_PAGES_REQUEST";
export const FETCH_LANDING_PAGES_SUCCESS = "FETCH_LANDING_PAGES_SUCCESS";
export const FETCH_LANDING_PAGES_FAILURE = "FETCH_LANDING_PAGES_FAILURE";
export const SET_ACTIVE_LANDING_PAGE = "SET_ACTIVE_LANDING_PAGE";
export const UNSET_ACTIVE_LANDING_PAGE = "UNSET_ACTIVE_LANDING_PAGE";
export const SET_LISTING_FILTER_STICKYNESS = "SET_LISTING_FILTER_STICKYNESS";
export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_FAILURE = "FETCH_PAYMENT_METHODS_FAILURE";
export const SHOW_FLEX_RATES_MODAL = "SHOW_FLEX_RATES_MODAL";
export const FETCH_SDP_QUOTE = "FETCH_SDP_QUOTE";
export const FETCH_SDP_QUOTE_REQUEST = "FETCH_SDP_QUOTE_REQUEST";
export const FETCH_SDP_QUOTE_SUCCESS = "FETCH_SDP_QUOTE_SUCCESS";
export const VERIFY_TOKEN_REQUEST = "VERIFY_TOKEN_REQUEST";
export const SAVE_QUOTATION_SEARCH_QUERY_STRING = "SAVE_QUOTATION_SEARCH_QUERY_STRING";
export const INIT_ACCOMMODATIONS_PAGE_SIZE = "INIT_ACCOMMODATIONS_PAGE_SIZE";
export const RESET_PARENT_SPONSOR_ID = "RESET_PARENT_SPONSOR_ID";
export const FETCH_TRIP_ADVISOR_DETAIL = "FETCH_TRIP_ADVISOR_DETAIL";

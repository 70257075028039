import { connect } from "react-redux";
import withRouter from "app/utils/hocs/withRouter";
import { getBrandDisplayName, getFooterData } from "app/reducers/partnerSelector";
import { checkIfPaymentCasino4xDisponible } from "app/pages/Booking/Payment/paymentSelector";
import { getContextCallCenter } from "app/pages/Booking/bookingSelectors";
import { getCurrentLandingPage } from "app/pages/LandingPage/Auth/LandingAuthSelector";

import Footer from "@brand/Footer/default/Footer";

const mapStateToProps = (state, ownProps) => {
	const contextCallCenter = getContextCallCenter({})(state);
	const { location } = ownProps;
	const isPaymentCasino4xDisponible = checkIfPaymentCasino4xDisponible(state);
	const isMerchPage = location.pathname.includes("/merch");
	const isQuotePage = location.pathname.includes("/quote");
	const isPaymentPage = location.pathname.includes("/payment");
	const showCasinoNote = (isQuotePage || isPaymentPage) && isPaymentCasino4xDisponible;
	const displayPhone = contextCallCenter.displayPhone || [];
	const footer = getFooterData(state);

	return {
		shop: state.shop,
		landingPageAuth: getCurrentLandingPage(state),
		helpContactUrl: contextCallCenter.helpContactUrl,
		displayPhone,
		cgvDocumentName: state.documents.cgv,
		legalesDocumentName: state.documents.legales,
		confidentialiteDocumentName: state.documents.confidentialite,
		partnerCode: state.partner.code,
		showConfigureCookieLink: Boolean(state.partner.technicalVars?.ONE_TRUST_SCRIPT),
		showCasinoNote,
		isMerchPage,
		resolution: state.resolution,
		brand: state.brand.code,
		paymentMethods: state.partner.availablePaymentMethods,
		additionalNote: footer.additionalNote,
		showReassurancePayment: footer.showReassurancePayment,
		useSVA: footer.useSVA,
		showShopSwitcher: footer.showShopSwitcher,
		showPfsMessage: footer.showPfsMessage,
		feedbackLink: footer.feedbackLink,
		additionalLinks: footer.additionalLinks,
		socialNetworksLinks: footer.socialNetworks,
		partnerRedirectionLink: footer.partnerRedirectionLink,
		brandDisplayName: getBrandDisplayName(state),
		brandCode: state.brand.code,
	};
};

const FooterContainer = withRouter(connect(mapStateToProps)(Footer));
export default FooterContainer;
